@import "../colors";
@import "../settings";

.kmta__tenant--pnp {
    .pnp__logo {
        position: relative;
        margin-right: 48px;
    }

    /*.kmta__headerBottom{
        background-color: #0069b4;
    }*/
    .pnp_svgIcon--logo {
        width: 151px;
    }

    .kmta__tenantLogoAlt--pnp {
        display: inline-block;
        width: 200px;
        max-width: 200px;
    }

    /*.kmta__headerBottomRow .kmta__menu  a:hover {
        font-weight: $global-weight-bold;
    }*/

    .kmta__headerNavWrapper{
        margin-left: -12px;
        padding-left: 0px;
    }
    .kmta__faqHome {
        background: linear-gradient(
            to bottom,
            rgba(236, 237, 237, 1) 0%,
            rgba(198, 199, 200, 1) 100%
        );
    }

    .kmta__faqBoxMoreLink{
        color: #f2f2f2;
        background-color: #0069b4;
        border-color: #0069b4;
        &:hover{
            color: #f2f2f2;
            background-color: #0069b4;
            border-color: #0069b4;
        }
    }

    .kmta__contactperson.is-expanded {
        height: 280px;
        transition: height 0.25s ease-in;

        .is-guest & {
            height: 280px;
        }
    }

    .dropdown.menu li.active > a,
    .menu.is-dropdown-submenu
        li.is-submenu-item.is-dropdown-submenu-item.active
        > a {
        font-weight: $global-weight-bold;
    }

    @include breakpoint(small down) {
        .kmta__headerTop {
            .kmta__tenantLogoAlt {
                display: none;
            }
            .kmta__headerUserinfo {
                margin-top: 0;
            }
        }
        .kmta__headerBottomRow {
            justify-content: space-between;
            .kmta__menu {
                justify-content: end;
                text-align: right;
            }
        }
    }
}
